<template>
  <div>
    <h3>Påstand:</h3>
    <h2 class="paastander">Alle undervisere bør ha mer fokus på studentaktiv undervisning.</h2><!-- Expression/Question -->
    <vsa-list>
      <vsa-item>
        <vsa-heading>
          Les mer her
        </vsa-heading>

        <vsa-content>
          <div class="explaination">
            <p>
              Studentaktiv undervisning er når foreleser går aktivt inn for at studenter skal arbeide med faginnholdet
              selv. Denne undervisningen går fra gruppearbeid til diskusjoner i forelesninger og større prosjektarbeid.
            </p>
            <p>
              Studentaktiv undervisning blir noen ganger trukket fram som et svar på hvordan universitetet kan øke
              arbeidslivsrelevans i studier. Er dette noe du mener at undervisere bør fokusere mer på?
            </p>
          </div>
        </vsa-content>
      </vsa-item>
    </vsa-list>
    <slot>></slot> <!-- Slot for react buttons -->
  </div>

</template>

<script>
import {
  VsaList,
  VsaItem,
  VsaHeading,
  VsaContent,
} from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';

export default {
  name: "Spm1",
  components:{
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
  }
}
</script>

<style scoped>

</style>